import {FunctionComponent, PropsWithChildren, useContext, useState} from 'react';
import Draggable from '../drag-drop/draggable';
import ContractRow from './contract-row';
import './contract-list.css';
import classNames from 'classnames';
import {Contract} from '../../types/contract';
import Input from '../form/input';
import {RackPocket} from '../../types/rack-pocket';
import PlacementContext from '../../contexts/placement-context';
import Button, {ButtonType} from '../common/button';
import {CircleIcon, XCircleIcon} from '@primer/octicons-react';
import {Rack} from '../../types/rack';

type ContractListProps = PropsWithChildren & {
    collapse: () => void
    contracts: Array<Contract>
    highlightContract?: string
    isCollapsed: boolean
    onOver?: (contract: string) => void
    onOut?: (contract: string) => void
    rack?: Rack
};

const ContractList: FunctionComponent<ContractListProps> = ({
                                                                collapse,
                                                                contracts,
                                                                highlightContract,
                                                                isCollapsed,
                                                                onOut,
                                                                onOver,
                                                                rack
                                                            }) => {
    const [keyword, setKeyword] = useState<string>('');
    const [filterDistCode, setFilterDistCode] = useState<string>('');

    const [filterInPockets, setFilterInPockets] = useState<boolean | undefined>();
    const placementContext = useContext(PlacementContext);

    const filteredContracts: Array<Contract> = keyword.length === 0 && filterInPockets === undefined && filterDistCode.length === 0 ? contracts : contracts.filter(contract => {
        const lowercaseKeyword = keyword.toLowerCase();
        const match = {
            keyword: true,
            inPocket: true,
            code: true
        };

        if (lowercaseKeyword.length > 0) {
            if (
                contract.contract.toLowerCase().search(lowercaseKeyword) < 0 &&
                contract.name.toLowerCase().search(lowercaseKeyword) < 0 &&
                contract.company.toLowerCase().search(lowercaseKeyword) < 0
            ) {
                match.keyword = false;
            }
        }

        if (filterInPockets !== undefined) {
            if (filterInPockets === true && placementContext.contractPositions[contract.contract] === undefined) match.inPocket = false;
            else if (filterInPockets === false && placementContext.contractPositions[contract.contract] !== undefined) match.inPocket = false;
        }

        if (filterDistCode.length > 0) {
            if (contract.distribution.filter(code => code.toString().toLowerCase() === filterDistCode.toLowerCase()).length === 0) match.code = false;
        }

        return match.keyword && match.inPocket && match.code;
    });

    const customPocket: RackPocket = {
        is_custom: true,
        name: 'Custom',
        pos_x: -1,
        pos_y: -1
    };

    return <div className={classNames('AdvertiserList', {collapsed: isCollapsed})}>
        <header onClick={() => collapse()}>Advertisers ({contracts.length})</header>
        {isCollapsed ? null : (
            <main>
                <div className="AdvertiserList-search">
                    <div className="AdvertiserList-search__section">
                        Search:<br/>
                        <Input value={keyword} onChange={val => setKeyword(val)}/>
                    </div>
                    <div className="AdvertiserList-search__section">
                        In Rack
                        <Button type={ButtonType.Plain}
                                onClick={() => setFilterInPockets(filterInPockets === true ? undefined : true)}>{filterInPockets === true ?
                            <XCircleIcon/> :
                            <CircleIcon/>} Yes</Button>
                        <Button type={ButtonType.Plain}
                                onClick={() => setFilterInPockets(filterInPockets === false ? undefined : false)}>{filterInPockets === false ?
                            <XCircleIcon/> :
                            <CircleIcon/>} No</Button>
                    </div>
                    <div className="AdvertiserList-search__section">
                        <select onChange={ev => setFilterDistCode(ev.target.value)} value={filterDistCode}>
                            <option value="">All Codes</option>
                            {rack && rack.codes.map(code => (
                                <option value={code.toString()}>{code.toString()}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <Draggable className="Advertisers-brochure"
                           item={customPocket}
                           bucket="advertiser">
                    {() => <>
                        <ContractRow/>
                    </>}
                </Draggable>
                {filteredContracts.map((contract, ix) => {
                    const pocket: RackPocket = {
                        contract: contract.contract,
                        name: contract.name,
                        dates: contract.start + ' ' + contract.end,
                        start: contract.start,
                        end: contract.end,
                        customer_id: contract.cust_id,
                        cust_id: contract.cust_id,
                        is_custom: false,
                        pos_x: -1,
                        pos_y: -1
                    };
                    return <Draggable key={ix + '_' + contract.contract}
                                      className="Advertisers-brochure"
                                      item={pocket}
                                      bucket="advertiser"
                    >{(pocket: RackPocket) => (
                        <ContractRow contract={contract}
                                     highlight={contract.contract === highlightContract}
                                     onOver={() => {
                                         if (onOver) onOver(contract.contract)
                                     }}
                                     onOut={() => {
                                         if (onOut) onOut(contract.contract)
                                     }}
                        />)
                    }</Draggable>
                })}
            </main>
        )}
    </div>;
}

// type SortableListProps<T = any> = {
//     items: Array<T>
//     children: (item: T) => ReactNode
// }
//
// const SortableList: FunctionComponent<SortableListProps<string>> = ({items, children}) => {
//     return <>
//         {
//             items.map((item, ix) => {
//                 return <Draggable key={item} onDragging={element => {
//                     console.log('Dragging element:', ix, element);
//                 }}>
//                     {children(item)}
//                 </Draggable>
//             })
//         }
//     </>
// };

export default ContractList;
